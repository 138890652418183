<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { tableData } from "./data";


/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "留言管理",
      items: [
        {
          text: "留言/聊天室管理",
          href: "/msgmanagement",
        },
        {
          text: "留言管理",
          active: true,
        }
      ],
      search_data:{
        status: 0,
      },
      searchConsoleVisible:true,
      value:'',
      daterange:'',
      visible: true,
      options: [
        "新聞",
        "影音節目",
        "選舉專區",
        "民調專區",
        "二級留言",
      ],

      tableData: tableData,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "姓名",
          sortable: true,
        },
        {
          key: "用戶編號",
          sortable: true,
        },
        {
          key: "留言時間",
          sortable: false,
        },
        {
          key: "留言位置",
          sortable: false,
        },
        {
          key: "留言內容",
          sortable: false,
        },
        {
          key: "檢舉次數",
          sortable: false,
        },
        {
          key: "操作",
          sortable: false,
        }
      ],
      tab: 1
    };
  },
  computed:{
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  methods:{
    changeStatus(_status){
      this.search_data.status = _status
    },
    changeTab(_status){
      this.tab = _status
    }
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.col-lg-12.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 篩選查詢
              i.fe-settings
            a.collapse-turn.mr-2(
              @click="searchConsoleVisible = !searchConsoleVisible",
              href="javascript: void(0);"
            )
              span(v-if="searchConsoleVisible") 收起
                i.fe-chevron-up
              span(v-else) 展開
                i.fe-chevron-down
            b-button.ml-1.width-lg(variant="primary", @click="reloadArticleList") 查詢
            b-button.ml-1.width-lg(variant="secondary",@click="clearSearchField") 清除
        //- 搜尋bar
        b-collapse#customaccordion-1(v-model="searchConsoleVisible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-4
                  .form-group
                    label 用戶管理
                    br
                    input.form-control(v-model="option", type="text")
                .col-lg-4
                  .form-group
                    label 姓名
                    br
                    input.form-control(v-model="option", type="text")
                .col-lg-4
                  .form-group
                    label 留言內容關鍵字
                    br
                    input.form-control(v-model="option", type="text")
              .row
                .col-lg-4
                  .form-group.mt-1
                    label 是否被檢舉
                    multiselect(v-model="value", :options="options", placeholder="", select-label="")
                .col-lg-4
                  .form-group
                    label.col-form-label(for="example-date") 結束時間
                    date-picker(
                      v-model="date",
                      append-to-body,
                      lang="en",
                      confirm,
                      placeholder="請選擇結束時間"
                    )
                .col-lg-4
                  .form-group.mt-1
                    label 留言位置
                    multiselect(v-model="value", :options="options", placeholder="", select-label="")

      //- 表格
      table.col-12
        .card
          .card-body
            .header-row.mb-3
              .header-title 留言查詢結果
            //- Table
            .table-responsive.mb-0
              b-table(:items="tableData", :fields="fields", responsive="sm", :per-page="perPage", :current-page="currentPage", :sort-desc.sync="sortDesc", :filter="filter", :filter-included-fields="filterOn", @filtered="onFiltered")
            .row.mb-md-2
              .col-6
                div(id="tickets-table_length", class="dataTables_length")
                  label.d-inline-flex.align-items-center
                    | Show
                    b-form-select.ml-2.mr-2(v-model="perPage", size="sm", :options="pageOptions")
                    | entries
              .col-6
                div(class="dataTables_paginate paging_simple_numbers float-right")
                  ul.pagination.pagination-rounded.mb-0
                    // pagination
                    b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem 
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
</style>
